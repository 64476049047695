//import 'core-js/stable';
//import 'regenerator-runtime/runtime';
import Swiper from 'swiper/swiper-bundle.min.js';
const MicroModal = require('micromodal');
import smoothscroll from 'smoothscroll-polyfill';
import { listen, prefetch } from 'quicklink';
// global.jQuery = global.$ = window.jQuery = require('jquery');

// quicklink
listen({
  origins: [
    's-echoes.jp',
    's-echoes.com'
  ],
  ignores: [
    /\/api\/?/,
    /#(.+)/,
    /tel:(.+)/,
    /mailto:(.+)/,
    uri => uri.includes('.zip'),
    (uri, elem) => elem.hasAttribute('noprefetch')
  ]
});

// smooth scroll behavior Polyfill
smoothscroll.polyfill();

// 透過ヘッダーかどうか
const isHeaderTransparent = (document.querySelector('.l-header--transparent') !== null) ? true : false;

// グローバルナビ
const gnav = document.getElementById('panel-gnav');
const gnavSub = document.querySelectorAll('.p-gnav__panel');
const gnavItem = document.querySelectorAll('.p-gnav__item:has(.p-gnav__panel)');
const gnavCtrl = document.querySelectorAll('.p-gnav__ctrl');

// ハンバーガーメニュー
const burger = document.getElementById('burger');

if(burger !== null){
  burger.addEventListener('click', (e) => {
    if (burger.getAttribute('aria-expanded') == 'false') {
      burger.setAttribute('aria-expanded', true);
      burger.setAttribute('aria-label', 'メニューを閉じる');
      gnav.setAttribute('aria-hidden', false);
      document.documentElement.classList.add('fixed');
    } else {
      burger.setAttribute('aria-expanded', false);
      burger.setAttribute('aria-label', 'メニューを開く');
      gnav.setAttribute('aria-hidden', true);
      document.documentElement.classList.remove('fixed');
    }
  });
}

// 関連事例カルーセル
const swiperRealatedWrapper = document.querySelector('.js-swiper-related .p-card-carousel__wrapper');
const swiperRelatedSlides = document.querySelectorAll('.js-swiper-related .p-card-carousel__item');
const swiperRelatedCtrls = document.querySelector('.js-swiper-related .p-card-carousel__ctrl');
const swiperRelatedPrevSlideMessage = '前のスライドへ';
const swiperRelatedNextSlideMessage = '次のスライドへ';
const initSwiperRelated = () => {
  const swiperRelated = new Swiper ('.js-swiper-related', {
    loop: true,
    slidesPerView: 'auto',
    spaceBetween: 24,
    speed: 500,
    grabCursor: true,
    updateOnWindowResize: false,
    pagination: {
      el: '.p-card-carousel__progressbar',
      clickable: true,
    },
    navigation: {
      nextEl: '.js-swiper-related .swiper-button-next',
      prevEl: '.js-swiper-related .swiper-button-prev',
    },
    a11y: {
      prevSlideMessage: swiperRelatedPrevSlideMessage,
      nextSlideMessage: swiperRelatedNextSlideMessage,
    },
    breakpoints: {
      768: {
        spaceBetween: 32,
      }
    },
  });

  swiperRelated.on('slideChange', ()=> {
    const prevRelatedBtn = document.querySelector('.js-swiper-related .swiper-button-prev');
    if(prevRelatedBtn !== null){
      if(prevRelatedBtn.classList.contains('disabled')){
        prevRelatedBtn.classList.remove('disabled');
      }
    }
  });
}

//media query
// const mediaQuerySm = window.matchMedia('(min-width: 576px)');
const mediaQueryMd = window.matchMedia('(min-width: 768px)');
const mediaQueryLg = window.matchMedia('(min-width: 992px)');
// const mediaQueryXl = window.matchMedia('(min-width: 1200px)');


// const mediaQuerySmListner = (mq) => {
//   if (mq.matches) { // 576px以上

//   } else { // 576px未満

//   }
// };

const mediaQueryMdListner = (mq) => {
  if (mq.matches) { // 768px以上
    // 関連事例カルーセル
    if(swiperRelatedSlides.length > 3) {
      if(swiperRealatedWrapper !== null){
        swiperRealatedWrapper.classList.remove('no-slide');
      }
      if(swiperRelatedCtrls !== null){
        swiperRelatedCtrls.style.display = 'flex';
      }
      initSwiperRelated();
    }else{
      if(swiperRealatedWrapper !== null){
        swiperRealatedWrapper.classList.add('no-slide');
      }
      if(swiperRelatedCtrls !== null){
        swiperRelatedCtrls.style.display = 'none';
      }
      if(swiperRelatedSlides.length > 0){
        swiperRelatedSlides[0].classList.add('swiper-slide-active');
      }
    }
  } else { // 768px未満
    // 関連事例カルーセル
    if(swiperRelatedSlides.length > 1) {
      if(swiperRealatedWrapper !== null){
        swiperRealatedWrapper.classList.remove('no-slide');
      }
      if(swiperRelatedCtrls !== null){
        swiperRelatedCtrls.style.display = 'flex';
      }
      initSwiperRelated();
    }else{
      if(swiperRealatedWrapper !== null){
        swiperRealatedWrapper.classList.add('no-slide');
      }
      if(swiperRelatedCtrls !== null){
        swiperRelatedCtrls.style.display = 'none';
      }
    }
  }
};

const mediaQueryLgListner = (mq) => {
  if (mq.matches) { // 992px以上
    // グローバルナビ
    gnav.setAttribute('aria-hidden', false);
    [].forEach.call(gnavCtrl, function(ctrl) {
      ctrl.setAttribute('aria-expanded', 'false');
    });
    [].forEach.call(gnavSub, function(panel) {
      panel.setAttribute('aria-hidden', 'true');
    });

    [].forEach.call(gnavItem, function(ctrl) {
      ctrl.addEventListener('mouseenter', function(e) {
        for (var i = 0; i < gnavCtrl.length; i++) {
          gnavCtrl[i].setAttribute('aria-expanded', 'false');
        }
        for (var i = 0; i < gnavSub.length; i++) {
          gnavSub[i].setAttribute('aria-hidden', 'true');
        }
        const targetPanel = e.currentTarget.querySelector('.p-gnav__ctrl').getAttribute('aria-controls');
        e.currentTarget.querySelector('.p-gnav__ctrl').setAttribute('aria-expanded', 'true');
        document.getElementById(targetPanel).setAttribute('aria-hidden', 'false');
      });
    });
    [].forEach.call(gnavItem, function(ctrl) {
      ctrl.addEventListener('mouseleave', function(e) {
        for (var i = 0; i < gnavCtrl.length; i++) {
          gnavCtrl[i].setAttribute('aria-expanded', 'false');
        }
        for (var i = 0; i < gnavSub.length; i++) {
          gnavSub[i].setAttribute('aria-hidden', 'true');
        }
      });
    });

  } else { // 992px未満
    // グローバルナビ
    gnav.setAttribute('aria-hidden', true);
    [].forEach.call(gnavCtrl, function(ctrl) {
      ctrl.setAttribute('aria-expanded', 'true');
    });
    [].forEach.call(gnavSub, function(panel) {
      panel.setAttribute('aria-hidden', 'false');
    });
  }
};

// const mediaQueryXlListner = (mq) => {
//   if (mq.matches) { // 1200px以上

//   } else { // 1200px未満

//   }
// };

// mediaQuerySm.addEventListener("change", mediaQuerySmListner);
mediaQueryMd.addEventListener("change", mediaQueryMdListner);
mediaQueryLg.addEventListener("change", mediaQueryLgListner);
// mediaQueryXl.addEventListener("change", mediaQueryXlListner);

// mediaQuerySmListner(mediaQuerySm);
mediaQueryMdListner(mediaQueryMd);
mediaQueryLgListner(mediaQueryLg);
// mediaQueryXlListner(mediaQueryXl);

// アコーディオン
const acdCtrl = document.querySelectorAll('.js-accrodion');
[].forEach.call(acdCtrl, (ctrl) => {
  ctrl.addEventListener('click', (e) => {
    const targetPanel = e.currentTarget.getAttribute('aria-controls');
    const targetElm = document.getElementById(targetPanel);
    const state = e.currentTarget.getAttribute('aria-expanded');
    if(state == 'false'){
      targetElm.style.height = targetElm.scrollHeight + 'px';
      e.currentTarget.setAttribute('aria-expanded', true);
      e.currentTarget.setAttribute('aria-label', '閉じる');
      targetElm.setAttribute('aria-hidden', false);

    }else{
      targetElm.style.height = 0;
      e.currentTarget.setAttribute('aria-expanded', false);
      e.currentTarget.setAttribute('aria-label', '開く');
      targetElm.setAttribute('aria-hidden', true);
    }
  });
});

// スクロール量取得
const getScrolled = () => {
  return (window.pageYOffset !== undefined) ? window.pageYOffset: document.documentElement.scrollTop;
}

// ページトップボタン
const pagetopBtn = document.querySelector('#pagetop');

// ページトップへスクロール
const scrollToTop = () => {
  pagetopBtn.addEventListener('click', () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  });
};
scrollToTop();

// ページ内リンクスムーススクロール（指定IDへスクロール）
const scrollToId = () => {
  const anchorLinks = document.querySelectorAll('a[href^="#"]');
  [].forEach.call(anchorLinks, (link) => {
    link.addEventListener('click', (e) => {
      e.preventDefault();
      // 固定ヘッダーなどの固定要素がある場合はその分の高さを考慮
      // 固定要素のセレクタはコードに合わせて適宜変更
      const headerHeight = document.querySelector('header') !== null ? document.querySelector('header').clientHeight : 0;
      const targetId = link.hash;
      const targetElement = document.querySelector(targetId);
      const targetOffsetTop = getScrolled() + targetElement.getBoundingClientRect().top - headerHeight;
      window.scrollTo({
        top: targetOffsetTop,
        behavior: "smooth"
      });
    });
  });
};
scrollToId();

// ハッシュ（ID）付きURLでロードされた場合
const loadScrollToId = () => {
  const targetId = location.hash;
  if(!targetId){
    return;
  }
  const targetElement = document.querySelector(targetId);
  if(targetElement === null){
    return;
  }
  const headerHeight = document.querySelector('header') !== null ? document.querySelector('header').clientHeight : 0;
  const targetOffsetTop = getScrolled() + targetElement.getBoundingClientRect().top - headerHeight;
  window.scrollTo({
    top: targetOffsetTop,
    behavior: "smooth"
  });
};

// Dom読み込み時実行
// document.addEventListener('DOMContentLoaded', () => {

// });


// コンテンツ読み込み完了後に実行
window.addEventListener('load', () => {
  // ハッシュ付きURLの場合の位置変更
  loadScrollToId();

  //フェードインアニメーション
  const animElement = document.querySelectorAll('.js-effect-fadein');
  const animElementArr = Array.prototype.slice.call(animElement);

  const fadeinObserver = (entries, observer) => {
    [].forEach.call(entries, (entry) => {
      if(entry.isIntersecting) {
        entry.target.classList.add('inview');
        observer.unobserve(entry.target);
      }
    });
  }
  const options = {
    rootMargin: "-100px 0px"
  }
  const io = new IntersectionObserver(fadeinObserver, options);
  io.POLL_INTERVAL = 100;
  [].forEach.call(animElementArr, (el) => {
    io.observe(el);
  });
});

// スクロール時実行
window.addEventListener("scroll", () => {
  // 透過ヘッダーの場合、スクロール位置で色変更
  const header = document.querySelector('.l-header');
  if(isHeaderTransparent){
    (getScrolled() > 100) ? header.classList.remove('l-header--transparent') : header.classList.add('l-header--transparent');
  }
});
